import './ContactPage.css';
import TopHeader from "../../shared/topHeader/TopHeader";
import Header from "../../shared/header/Header";
import Copyright from "../../shared/copyright/Copyright";
import Footer from "../../shared/footer/Footer";
import SinglePageBanner from "../../shared/singlePageBanner/SinglePageBanner";
import Contact from '../../shared/contact/Contact';
import Clients from "../../shared/clients/Clients";
import appConstant from "../../shared/constant/constant.json";


function ContactPage() {
    return (
        <>
        <TopHeader/>
        <Header id='6' white='true'/>
        <SinglePageBanner page="Contact Us"/>
        <section className={'pages-content'}>
            <div className='contact-flex'>
                <div className="contact-box">
                    <div className="icon">
                        <img className='phone' alt='icon' src='/img/phone-black.png' />
                    </div>
                    <div className="contact-content">
                        <h4>Phone</h4>
                        <a href={`tel:+91 + ${appConstant.PHONE_NUMBER}`} className='links'> +91 {appConstant.PHONE_NUMBER}</a>
                    </div>
                </div>
                <div className="contact-box">
                    <div className="icon">
                        <img alt='icon' className='whatsapp' src='/img/whatsapp-black.png' />
                    </div>
                    <div className="contact-content">
                        <h4>Whatsapp</h4>
                        <a rel="noreferrer" href="https://api.whatsapp.com/send?phone=919891054016&amp;text=Hello" target="_blank" className='links'>+91 {appConstant.PHONE_NUMBER}</a>
                    </div>
                </div>
                <div className="contact-box">
                    <div className="icon">
                        <img alt='icon' className='email' src='/img/email-black.png' />
                    </div>
                    <div className="contact-content">
                        <h4>E-mail</h4>
                        <a href="mailto:hi@idmvalley.com" className='links'>hi@idmvalley.com</a>
                    </div>
                </div>
                <div className="contact-box">
                    <div className="icon">
                        <img alt='icon' className='home' src='/img/home-black.png' />
                    </div>
                    <div className="contact-content">
                        <h4>Location</h4>
                        <a href='https://goo.gl/maps/vmdGqJBp61epkk4Q6' rel="noreferrer" target="_blank" className='links'>A7/15 Ground Floor, Sec 85 BPTP, Faridabad, HR, 121002</a>
                    </div>
                </div>                
            </div>
        </section>
        <Contact/>
        <hr/>
        <Clients/>
        <Footer/>
        <Copyright/>
        </>
    );
  }
  
  export default ContactPage;